import React from 'react'

function ServiceBox({imgName, box_name, description}) {
    return (
        <div className="service_box">
            <img src={imgName} alt=""></img>
            <h2>{box_name}</h2>

            <p>{description}</p>
        </div>
    )
}

export  { ServiceBox }